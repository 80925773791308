import React, { useEffect, useState } from "react";
import { Gift } from "../Gift";
import { Loader } from "../Loader";

//CSS
import "./style.scss";

export const Gifts: React.FC = () => {
  const [gifts, setGifts] = useState([]);

  const loadGifts = async () => {
    try {
      const res = await fetch("/.netlify/functions/requests");
      const gifts = await res.json();
      setGifts(gifts);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadGifts();
  }, []);

  return (
    <div className="gifts">
      <h2>Önskelista.</h2>
      <p>
        Att ge en gåva är förstås valfritt, men här är några saker vi önskar
        oss.
      </p>
      <ul>
        {gifts.length > 0 ? (
          [
            gifts.map((gift) => (
              <li>
                <Gift gift={gift} loadGifts={loadGifts} />
              </li>
            )),
          ]
        ) : (
          <div className="gifts-loader">
            <Loader />
          </div>
        )}
      </ul>
    </div>
  );
};
