import React from "react";
import "./style.scss";

export const Header: React.FC = () => {
  return (
    <div className="header">
      <a href="/">
        <p> A | O </p>
      </a>
    </div>
  );
};
