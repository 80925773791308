import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Gifts } from "./Components/Gifts";
import { Header } from "./Components/Header";

import "./App.css";

export const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <Router>
        <Routes>
          <Route path="/" element={<Gifts />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
