import React, { useState, useEffect, useRef } from "react";

import { Loader } from "../Loader";

//CSS
import "./style.scss";

export const Gift: React.FC = ({ gift, loadGifts }) => {
  const [isLoading, setIsLoading] = useState(false);

  const submitRequest = async () => {
    setIsLoading(true);

    try {
      // /api/requests
      await fetch("/.netlify/functions/requests", {
        method: "PUT",
        body: JSON.stringify({
          ...gift,
          numberReserved: gift.numberReserved + 1,
        }),
      }).then(() =>
        setTimeout(function () {
          setIsLoading(false);
        }, 1000)
      );
      loadGifts();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="gift">
      {isLoading === false ? (
        <div className="gift-item">
          <a href={gift.url} rel="noreferrer" target="_blank">
            <img src={gift.image} />
          </a>
          <div className="gift-item-content">
            <div>
              {gift.numberWanted - gift.numberReserved > 0 ? (
                <h3>{gift.name}</h3>
              ) : (
                <h3 className="grey">{gift.name}</h3>
              )}
              <p>
                {gift.numberWanted - gift.numberReserved > 0 ? (
                  <p>
                    Vi önskar oss {gift.numberWanted - gift.numberReserved}{" "}
                    stycken.
                  </p>
                ) : (
                  <p className="grey">
                    Någon annan har varit snäll och köpt de vi önskade oss.
                  </p>
                )}
              </p>
            </div>
          </div>
          {gift.numberWanted - gift.numberReserved > 0 ? (
            <button onClick={() => submitRequest()}> Jag har köpt en! </button>
          ) : (
            []
          )}
        </div>
      ) : (
        <div className="gift-item-loader">
          <Loader />
        </div>
      )}
    </div>
  );
};
