import React from "react";

//CSS
import "./style.scss";

//Components

//Assets
import loader from "../../Assets/img/loader.svg";

export const Loader: React.FC = () => {
  return (
    <div className="loader">
      <img src={loader} width="48" height="60" />
    </div>
  );
};
